import { Box, Container, Stack, styled, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import {
  IcoBlueEllipse,
  IcoGreenCircle,
  IcoRedEllipse,
} from 'components/@icons';
import { Section } from 'components/@screens';

const Image = styled('img')`
  object-fit: cover;
  width: 100%;
  border-radius: 0px 40px 40px 0px;
  overflow: hidden;
  position: relative;
`;

const Info = () => {
  return (
    <Section name="info">
      <Container
        maxWidth="lg"
        sx={{
          py: 8,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box
          position="relative"
          ml={{ sm: 0, md: 10 }}
          display="flex"
          justifyContent="center"
        >
          <Box position="absolute" top={-40} right={-40}>
            <IcoRedEllipse fontSize={90} aria-label="Rode ellipse" />
          </Box>
          <Image
            src="/assets/img-info.webp"
            alt="Kinesist Kelly Van Moer"
            srcSet="/assets/img-info@2x.webp 2x, /assets/img-info.webp 1x"
            width={376}
            height={476}
            sx={{
              width: { xs: '80%', sm: '50%', md: 376 },
              height: { xs: 'auto', md: 476 },
              maxWidth: '100%',
            }}
          />
          <Box position="absolute" top={-40} left={-40}>
            <IcoGreenCircle fontSize={150} aria-label="Groene cirkel" />
          </Box>
          <Box position="absolute" bottom={-40} left={-40}>
            <IcoBlueEllipse fontSize={110} aria-label="Blauwe ellipse" />
          </Box>
        </Box>
        <Stack
          gap={2}
          flex={1}
          justifyContent="center"
          display="flex"
          ml={{ xs: 0, md: 6 }}
          mt={{ xs: 6, md: 0 }}
        >
          <Typography variant="h1" color="global01">
            <FormattedMessage id="home.info.title" />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage id="home.info.body" />
          </Typography>
        </Stack>
      </Container>
    </Section>
  );
};

export default Info;
