import { Box, Button, Container, Link, Stack, Typography } from '@mui/material';
import posthog from 'posthog-js';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IcoGreenCircle, IcoRedEllipse } from 'components/@icons';
import { Section } from 'components/@screens';

const Appointment = () => {
  const intl = useIntl();

  const [bookingUrl, setBookingUrl] = useState<string>(
    'https://bookings.crossuite.app/8b223ca3-d053-4c49-91d3-d83202f47a10/',
  );

  useEffect(() => {
    setBookingUrl(
      `https://bookings.crossuite.app/8b223ca3-d053-4c49-91d3-d83202f47a10/?p=${posthog.get_distinct_id()}`,
    );
  }, []);

  return (
    <Section name={['afspraak', 'locatie']}>
      <Container
        maxWidth="lg"
        sx={{
          py: 8,
        }}
      >
        <Stack
          position="relative"
          bgcolor="neutral02"
          width={{ sm: '100%', md: '70%' }}
          borderRadius="0px 40px 40px 0px"
          p={5}
          gap={3}
        >
          <Box position="absolute" top={-40} right={-40}>
            <IcoRedEllipse fontSize={90} aria-label="Rode ellipse" />
          </Box>
          <Box
            position="absolute"
            bottom={-40}
            left={-40}
            sx={{ transform: 'scaleX(-1)' }}
          >
            <IcoGreenCircle fontSize={150} aria-label="Groene cirkel" />
          </Box>
          <Typography variant="h2" color="global01">
            <FormattedMessage id="home.appointment.title" />
          </Typography>
          <Box>
            <Typography variant="body1" color="global01">
              <b>
                <FormattedMessage id="home.appointment.address.title" />
              </b>
            </Typography>
            <Typography variant="body1" component="address">
              <FormattedMessage id="home.appointment.address.body" />
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" color="global01">
              <b>
                <FormattedMessage id="home.appointment.phone.title" />
              </b>
            </Typography>
            <Link
              href={`tel:${intl.formatMessage({
                id: 'home.appointment.phone.body',
              })}`}
              onClick={() => {
                posthog.capture('appointment_phone_click');
              }}
            >
              <Typography variant="body1">
                <FormattedMessage id="home.appointment.phone.body" />
              </Typography>
            </Link>
          </Box>
          <Box>
            <Typography variant="body1" color="global01">
              <b>
                <FormattedMessage id="home.appointment.email.title" />
              </b>
            </Typography>
            <Link
              href={`mailto:${intl.formatMessage({
                id: 'home.appointment.email.body',
              })}`}
              onClick={() => {
                posthog.capture('appointment_mail_click');
              }}
            >
              <Typography variant="body1">
                <FormattedMessage id="home.appointment.email.body" />
              </Typography>
            </Link>
          </Box>

          <Button
            sx={{ marginRight: 'auto' }}
            href={bookingUrl}
            target="_blank"
            onClick={() => {
              posthog.capture('appointment_online_click');
            }}
          >
            <FormattedMessage id="home.appointment.create.online.button" />
          </Button>
          <Typography
            variant="body1"
            fontSize={14}
            lineHeight="24px"
            maxWidth={400}
          >
            <FormattedMessage id="home.appointment.create.body" />
          </Typography>
          <Box
            position={{ xs: 'relative', md: 'absolute' }}
            left={{ xs: 0, md: '80%' }}
            top="50%"
            width={{ xs: '100%', md: 400, lg: 500 }}
            height={{ xs: '200', md: 400 }}
            sx={{ transform: { xs: 'unset', md: 'translateY(-50%)' } }}
            boxShadow="2px 2px 18px rgba(44, 66, 121, 0.25)"
            borderRadius="10px"
            overflow="hidden"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d214.42255175736926!2d4.369350622579372!3d50.98909731313991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9cdb9f62f7493a1e!2sKine%20Van%20Moer!5e0!3m2!1sen!2sbe!4v1670485756782!5m2!1sen!2sbe"
              width="100%"
              height="100%"
              aria-label="Google maps van Kine Van Moer"
              style={{
                border: 0,
              }}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Box>
        </Stack>
      </Container>
    </Section>
  );
};

export default Appointment;
